import React from 'react'
import { FaFilePdf, FaFileWord, FaFileExcel, FaFileImage, FaFileArchive, FaFile } from 'react-icons/fa'

export const IconMaterial = ({ typeFile }) => {

  let IconComponent = null

  switch (typeFile) {
    case 'pdf':
      IconComponent = <FaFilePdf />
      break;

    case 'docx':
      IconComponent = <FaFileWord />
      break;

    case 'doc':
      IconComponent = <FaFileWord />
      break;

    case 'xlsx':
      IconComponent = <FaFileExcel />
      break;

    case 'xls':
      IconComponent = <FaFileExcel />
      break;

    case 'png':
      IconComponent = <FaFileImage />
      break;

    case 'jpg':
      IconComponent = <FaFileImage />
      break;

    case 'zip':
      IconComponent = <FaFileArchive />
      break;

    case 'rar':
      IconComponent = <FaFileArchive />
      break;

    default:
      IconComponent = <FaFile />
      break;
  }

  return IconComponent
}
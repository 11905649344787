import React from 'react'

// functions
import { stripSlashes } from '~/services/functions'

// style
import { Wrap } from './style'

const InputRadio = ({ label, disabled, nameRadio, ...rest }) => {
  return (
    <Wrap {...rest}>
      <label>
        <span>
          <input type="radio" name={nameRadio} disabled={disabled} />
          <div className="circle-check"></div>
        </span>
        <span>
          {<div dangerouslySetInnerHTML={{ __html: stripSlashes(label) }} />}
        </span>
      </label>
    </Wrap>
  )
}
export default InputRadio
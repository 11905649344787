import React from 'react'
import { SelectStyled } from './style'

const Select = ({ data, indexOption, dataOption, ...rest }) => {

  let idxOpt = { velue: 'value', label: 'label' }

  if (indexOption) {
    idxOpt = indexOption;
  }

  return (
    <div className="form-group">
      <SelectStyled {...rest}>
        <option value=""></option>
        {
          data &&
          data.map((itemData, indexData) => {

            let dataAttr = {};

            if (dataOption) {

              dataOption.forEach((itemAttr, idnexAttr) => {
                dataAttr[itemAttr.name] = itemData[itemAttr.idx];
              })
            }

            return (
              <option key={indexData} value={itemData[idxOpt.value]} {...dataAttr}>{itemData[idxOpt.label]}</option>
            )
          })
        }
      </SelectStyled>
    </div>
  )
}

export default Select
import styled from 'styled-components'
import { color, media } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const WrapForm = styled.div`
  width:410px;
  max-width: 100%;

  h1{
    color: ${color.fourth}
  }
  h2{
    margin-bottom: 30px;
    text-align: center;

    ${media.xl}{
      margin-bottom: 55px;
    }
  }
  a{
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: ${color.second} !important;

    span{
      color: ${color.fourth}
    }

    &:hover{
      text-decoration: underline;
    }
  }
`;
export const WrapSocialLogin = styled.div`
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  button:first-child{
    margin-bottom: 10px;
  }
`

export const Separator = styled.span`
  width: 300px;
  max-width: 100%;
  display: block;
  text-align: center;
  position: relative;
  margin: 20px auto;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    ${mixin.transform('translate(0,-50%)')}
    height: 1px;
    background: #e2e2e2;
  }

  span {
    display: inline-block;
    position: relative;
    z-index: 2;
    width: 80px;
    line-height: 1;
    padding: 5px;
    background: #ffffff;
    color: ${color.third};
    font-size: 12px;
    font-weight: 700;
  }
`;
export const Form = styled.form`
  display: block;
  margin-bottom: 42px;
  opacity: ${props => props.sending ? '.5' : '1'};
  pointer-events: ${props => props.sending ? 'none' : 'all'};

  a{
    color: ${color.third} !important;
  }
`;

import React from 'react'

// style
import { Container, ModuleWrap, ModuleItem, ClassWrap, ClassItem } from './style'

const UnfinishedClass = ({ classes }) => {

  return (
    <Container>
      <h5>Verifique as aulas abaixo:</h5>
      <ModuleWrap>
        {classes && classes.map((itemModule, indexModule) => {
          return (
            <ModuleItem key={indexModule}>{itemModule.title}
              <ClassWrap>
                {itemModule.class && itemModule.class.map((itemClass, indexClass) => <ClassItem key={indexClass}>{itemClass.title}</ClassItem>)}
              </ClassWrap>
            </ModuleItem>
          )
        })}
      </ModuleWrap>
    </Container>
  )
}

export default UnfinishedClass
import styled from 'styled-components'
import { color, media } from '~/styles/variables'
import Button from '~/components/form/Button'

export const Section = styled.section`
  width: 100%;
`
export const WrapForm = styled.div`

  ${media.md}{
    display: flex;
  }
  
  .left-side{
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;

    ${media.md}{
      width: 343px;
      margin-bottom: 0;
    }
  }
  .right-side{
    width: 100%;
    max-width: 100%;

    ${media.md}{
      width: 490px;
      padding-left: 50px;
    }

    h3{
      font-weight: 300;
      color: ${color.third};
      margin: 0 0 30px 0;
    }
  }
  
`
export const Photo = styled.div`
  width: 195px;
  height: 195px;
  /* padding-bottom: 100%; */
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #000000;
  
  ${media.sm}{
    width: 265px;
    height: 265px;
  }

  .bg{
    background-image: url(${props => props.avatar});
    transition: all .2s ease-in-out;
  }

  input{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    opacity: 0;
    cursor: pointer;
  }
  span{
    position: relative;
    z-index: 1;
    color: #FFFFFF;
    font-size: 13px;
    transition: all .2s ease-in-out;
    opacity: 0;
  }

  &:hover{

    .bg{
      opacity: .6;
    }
    span{
      opacity: 1;
    }
  }
`
export const RemovePhoto = styled(Button)`
  background: transparent;
  color: ${color.second};
  margin-top: 10px;
  border: none;

  &:hover{
    color: ${color.second};
  }
`
export const WrapChangePassContact = styled.div`
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  
  a.button-nav-change-pass{
    margin: 20px 0 30px 0;
    color: #FFFFFF !important;
  }

  p{
    text-align: center;

    a{
      display: block;
    }
  }
`
export const WrapFormChangePassword = styled.div`
  width: 500px;
  max-width: 100%;

  h3{
    font-weight: 300;
    color: ${color.third};
    margin: 0 0 30px 0;
  }
`
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// images
import logo from '~/assets/logo/proordem-oline-n.svg'
import leftArrow from '~/assets/icons/left-arrow.svg'

// Context
import { AuthContext } from '~/hooks/auth'

//style 
import { MainContainer, Content, Logo, BackButton } from './style'
import { Redirect } from 'react-router-dom'

// main style
import { color } from '~/styles/variables'

//conf
import { BASE_URL_SITE } from '~/services/conf'
import { getCookie } from '~/services/functions'

// components
import FormLogin from './Form'

const Login = () => {

  const auth = useContext(AuthContext)

  const [loginData, setLoginData] = useState({ email: '', senha: '' })

  const handleFormFill = field => evt => {
    setLoginData({
      ...loginData,
      [field]: evt.target.value
    })
  }

  const handleLogin = () => {
    auth.signIn.signInUser(loginData)
  }

  const tokenCookie = getCookie('@ProordemOnline:token')

  if (tokenCookie) {
    return <Redirect to='/' />
  }

  return (
    <MainContainer>
      <Content bg={color.primary}>
        <BackButton href={`${BASE_URL_SITE}`} title="Ir para o site">
          <img src={leftArrow} alt="Seta para trás" />
          Ir para o site
        </BackButton>
        <Logo src={logo} alt="Proordem Online" />
      </Content>
      <Content>
        <FormLogin
          handleFormFill={handleFormFill}
          handleLogin={handleLogin}
          formData={loginData}
          sending={auth.signIn.signInState.loading}
          resMsg={auth.signIn.signInState.error} />
        <Link to="/politica-de-privacidade">Políticas de privacidade</Link>
      </Content>
    </MainContainer>
  )
}

export default Login
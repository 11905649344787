import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { pt } from 'date-fns/locale'

// config
import { MINIMUM_VIDEO_PLAYED } from '~/services/conf'
import { BLOCK_BY_VIDEO_PAYED } from '~/services/conf'

// internal components
import Video from './Video'
import ButtonStatus from './ButtonStatus'

// style
import { Container, LabelFinishedCourse, WrapButtonFinishedClass, WrapTextDesc, WrapVideo } from './style'

// component
import { LoadingCourseContent } from '~/components/Loading'
import NextClassOrTest from '~/components/NextClassOrTest'

// rest
import { useGet, usePut } from '~/services/rest'

// functions
import { stripSlashes } from '~/services/functions'

const Content = ({ idHistoricClass, handleTestContent, handleClassContent, setUpdateCourseSidebar, setMinPercentagePlayed }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const classContent = useGet(`historicoaulas/${idHistoricClass}`)
  const courseContent = useGet(classContent.data[0] ? `historicocursos/${classContent.data[0].historico_modulos.historico_cursos_id}` : false)

  const [, updateHistoricCLass] = usePut(classContent.data.length > 0 && `historicoaulas/${classContent.data[0].idhistorico_aulas}`)

  const [needToTest, setNeedToTest] = useState(true)
  const [videoWatched, setVideoWatched] = useState(null)
  const [percentagePlayed, setPercentagePlayed] = useState(0)
  const [lastPercentagePlayed, setLastPercentagePlayed] = useState(0)

  // zera contador de porcentagem sempre que carrega tela
  useEffect(() => {
    setPercentagePlayed(classContent.data[0]?.video_assistido_porcentagem)
    setLastPercentagePlayed(classContent.data[0]?.video_assistido_porcentagem)
    setMinPercentagePlayed(false)
  }, [classContent.data, setMinPercentagePlayed])

  // verifica se usuário ja passou no teste se houver
  useEffect(() => {
    if (classContent.data.length > 0 && classContent.data[0].teste_resultado.length > 0) {
      for (let i = 0; classContent.data[0].teste_resultado.length > i; i++) {
        if (classContent.data[0].teste_resultado[i].aprovado) {
          setNeedToTest(false)
          break
        }
      }
    }
  }, [classContent.data])

  // pega dados se usuario ja assistiu o mínimo desse video 
  useEffect(() => {
    if (classContent.data.length > 0) {
      setVideoWatched(classContent.data[0].video_assistido === 1)
      setMinPercentagePlayed(classContent.data[0].video_assistido === 1) // estado da página principal (Course) para controle da RightSidebar
    }
  }, [classContent.data, setMinPercentagePlayed])

  // verifica em tempo real do player se o usuário ja assitiu o tanto mínimo do vídeo 
  useEffect(() => {
    if (percentagePlayed >= MINIMUM_VIDEO_PLAYED) {
      if (!videoWatched) {
        updateHistoricCLass({ video_assistido: true })
        setVideoWatched(true)
        setMinPercentagePlayed(true)  // estado da página principal (Course) para controle da RightSidebar
        setUpdateCourseSidebar(true)
      }
    }
  }, [percentagePlayed, updateHistoricCLass, videoWatched, setVideoWatched, setUpdateCourseSidebar, setMinPercentagePlayed])

  useEffect(() => {
    if (percentagePlayed > lastPercentagePlayed) {
      updateHistoricCLass({ video_assistido_porcentagem: percentagePlayed })
      setLastPercentagePlayed(percentagePlayed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentagePlayed])

  // loading component
  if (classContent.data.length === 0 || classContent.loading) {
    return <LoadingCourseContent />
  }

  // error component
  if (classContent.error) {
    return (
      <Container>
        <h2>Ops, algo deu errado!</h2>
        <p><strong> {classContent.error} </strong></p>
        <p>Por gentileza, tente novamente</p>
      </Container>
    )
  }

  return (
    <Container>
      {
        classContent.data[0].aulas.video_id &&
        <WrapVideo>
          <Video
            idVideo={classContent.data[0].aulas.video_id}
            videoWhatched={videoWatched}
            setPercentagePlayed={setPercentagePlayed}
            historicClass={classContent.data[0]}
            setUpdateCourseSidebar={setUpdateCourseSidebar} />
          <div className='wrap-infos-video'>
            <p className='info-percentage'>Para concluir esta aula você deverá assistir o mínimo de 80% de uma só vez</p>
            <p className={`percentage-watched ${percentagePlayed >= 80 && 'finished'}`}>{percentagePlayed}%</p>
          </div>
        </WrapVideo>
      }

      {
        videoWatched
          ?
          classContent.data[0].aulas.habilita_teste // verifica se aula possui teste
            ?
            !needToTest && // se usuário não precisa fazer o teste então renderiza o botão
            <WrapButtonFinishedClass>
              <ButtonStatus
                classContent={classContent.data[0]}
                setUpdateCourseSidebar={setUpdateCourseSidebar} />
              {
                classContent.data[0].concluido_em &&
                <p>Aula concluída em: <br />
                  <span>{format(new Date(classContent.data[0].concluido_em), 'dd/MM/yy', { locale: pt })}</span>
                  às
                  <span>{format(new Date(classContent.data[0].concluido_em), 'HH:mm', { locale: pt })}</span>
                </p>
              }
            </WrapButtonFinishedClass>

            :
            <WrapButtonFinishedClass>
              <ButtonStatus // se não tiver teste também renderiza o botão
                classContent={classContent.data[0]}
                setUpdateCourseSidebar={setUpdateCourseSidebar} />
              {
                classContent.data[0].concluido_em &&
                <p>Aula concluída em: <br />
                  <span>{format(new Date(classContent.data[0].concluido_em), 'dd/MM/yy', { locale: pt })}</span>
                  às
                  <span>{format(new Date(classContent.data[0].concluido_em), 'HH:mm', { locale: pt })}</span>
                </p>
              }
            </WrapButtonFinishedClass>
          :
          <></>
      }

      <h2 className='title'>{stripSlashes(classContent.data[0].aulas.titulo)}</h2>

      {
        classContent.data[0].aulas.descricao &&
        <WrapTextDesc dangerouslySetInnerHTML={{ __html: stripSlashes(classContent.data[0].aulas.descricao) }} />
      }

      {
        BLOCK_BY_VIDEO_PAYED ?
          videoWatched &&
          <NextClassOrTest
            classData={classContent.data[0]}
            handleTestContent={handleTestContent}
            handleClassContent={handleClassContent}
            setUpdateCourseSidebar={setUpdateCourseSidebar} />
          :
          <NextClassOrTest
            classData={classContent.data[0]}
            handleTestContent={handleTestContent}
            handleClassContent={handleClassContent}
            setUpdateCourseSidebar={setUpdateCourseSidebar} />
      }

      {
        courseContent.data[0] && courseContent.data[0].concluido_em && courseContent.data[0].status === 11 && courseContent.data[0].cursos.certificado_automatico === 1 &&
        <LabelFinishedCourse>Curso concluído em:
          <span>{format(new Date(courseContent.data[0].concluido_em), 'dd', { locale: pt })}</span>
          de
          <span>{format(new Date(courseContent.data[0].concluido_em), 'MMMM', { locale: pt })}</span>
          às
          <span>{format(new Date(courseContent.data[0].concluido_em), 'HH:mm', { locale: pt })}</span>
        </LabelFinishedCourse>
      }
    </Container>
  )
}

export default Content
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

// Context
import { AuthContext } from '~/hooks/auth'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section, WrapForm, Photo, RemovePhoto, WrapChangePassContact } from './style'
import { color } from '~/styles/variables'

// components
import Label from '~/components/form/Label'
import Input from '~/components/form/Input'
import Select from '~/components/form/Select'
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'
import WrapOtherCountry from './WrapOtherCountry'

// images
import DefaultAvatar from '~/assets/default-avatar.jpg'

// functions
import { cpf_validation, clear_mask, createCookie } from '~/services/functions'

// rest
import { usePut, usePost } from '~/services/rest'

// arrays
import countries from '~/services/arr-countries'
import br_states from '~/services/arr-states'

const Profile = () => {

  const auth = useContext(AuthContext)

  const [warningCompleteProfile,] = useState(auth.user.cadastro_confirmacao === 0 ? true : false)

  const [profileData, setProfileData] = useState({
    avatar: '',
    avatar_url: '',
    new_avatar: null,
    nome: '',
    cpf: '',
    email: '',
    celular: '',
    empresa: '',
    cep: '',
    pais: '',
    estado: '',
    cidade: '',
    bairro: '',
    endereco: '',
    numero: '',
    complemento: '',
  })

  const [isBrazil, setIsBrazil] = useState(true);

  useEffect(() => {
    if (auth.user) {

      setProfileData({
        avatar: auth.user.avatar ? auth.user : '',
        avatar_url: auth.user.avatar_url ? auth.user.avatar_url : '',
        new_avatar: null,
        nome: auth.user.nome ? auth.user.nome : '',
        email: auth.user.email ? auth.user.email : '',
        celular: auth.user.celular ? auth.user.celular : '',
        cep: auth.user.cep ? auth.user.cep : '',
        empresa: auth.user.empresa ? auth.user.empresa : '',
        cpf: auth.user.cpf ? auth.user.cpf : '',
        pais: auth.user.pais ? auth.user.pais : '',
        estado: auth.user.estado ? auth.user.estado : '',
        cidade: auth.user.cidade ? auth.user.cidade : '',
        bairro: auth.user.bairro ? auth.user.bairro : '',
        endereco: auth.user.endereco ? auth.user.endereco : '',
        numero: auth.user.numero ? auth.user.numero : '',
        complemento: auth.user.complemento ? auth.user.complemento : '',
      })

      if (auth.user.pais === 'br' || !auth.user.pais) {
        setIsBrazil(true);
      } else {
        setIsBrazil(false);
      }
    }
  }, [auth.user])

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const [resPhotoProfile, changePhotoProfile] = usePost(`usuarios/${profileData.idusuarios}/avatar`) // atualiza foto de perfil
  const [resUserProfile, changeUserProfile] = usePut(`usuarios`) // atualiza dados do aluno

  useEffect(() => {
    if (resPhotoProfile.error) {
      setError(resPhotoProfile.error)
    } else if (resUserProfile.error) {
      setError(resUserProfile.error)
    } else if (resUserProfile.data.length > 0 || resUserProfile.data.sucesso) {

      // atualiza cookie de usuário
      createCookie('@ProordemOnline:user', JSON.stringify(resUserProfile.data.usuarioUpdate))
      setSuccess("Atualizando, aguarde...")

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [resPhotoProfile, resUserProfile, setError])

  const handleFormFill = field => evt => {
    setProfileData({
      ...profileData,
      [field]: evt.target.value
    })
  }

  const handleUpdateUser = async () => {

    setError(null)

    if (
      !profileData.nome ||
      !profileData.cpf ||
      !profileData.celular ||
      !profileData.cep ||
      !profileData.estado ||
      !profileData.cidade ||
      !profileData.bairro ||
      !profileData.endereco ||
      !profileData.numero
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    if (!isBrazil && !profileData.pais) {
      setError('Por favor, informe seu país!')
      return false
    }

    // valida cpf
    if (!cpf_validation(clear_mask(profileData.cpf))) {
      setError('CPF inválido!')
      return false
    }

    // recupera valores sem formatação de mascara
    const putData = {
      nome: profileData.nome,
      // email: profileData.email,
      empresa: profileData.empresa,
      cpf: clear_mask(profileData.cpf),
      celular: clear_mask(profileData.celular),
      cep: clear_mask(profileData.cep),
      pais: profileData.pais,
      estado: profileData.estado,
      cidade: profileData.cidade,
      bairro: profileData.bairro,
      endereco: profileData.endereco,
      numero: profileData.numero,
      complemento: profileData.complemento,
      cadastro_confirmacao: 1,
    }

    if (!putData.pais) delete putData.pais

    // faz upload de novo avatar se houver
    if (profileData.new_avatar) {
      if (profileData.new_avatar === 'remove') {
        await changePhotoProfile({ avatar: null }) // caso queira remover avatar
      } else {
        await changePhotoProfile(profileData.new_avatar)
      }
    }

    await changeUserProfile(putData)
  }

  const handlePhotoUser = e => {
    const new_avatar = Array.from(e.target.files)

    if (new_avatar.length > 0) {
      const preview = URL.createObjectURL(new_avatar[0])

      const formData = new FormData()
      formData.append('avatar', new_avatar[0])

      setProfileData({
        ...profileData,
        avatar_url: preview,
        avatar: new_avatar[0]['name'],
        new_avatar: formData
      })

    } else {
      setProfileData({
        ...profileData,
        avatar_url: auth.user.avatar_url,
        avatar: auth.user.avatar,
        new_avatar: null
      })
    }
  }

  const handleRemovePhoto = () => {
    setProfileData({
      ...profileData,
      avatar_url: null,
      avatar: null,
      new_avatar: 'remove'
    })
  }

  const handleChangeFieldsCountry = () => {
    setIsBrazil(!isBrazil);

    setProfileData({
      ...profileData,
      cpf: '',
      pais: '',
      estado: '',
      cep: '',
      cidade: '',
      bairro: '',
      endereco: '',
      numero: '',
      complemento: '',
      celular: '',
    })
  }

  const handleChangeCountry = field => evt => {

    const codeFone = "+" + evt.target[evt.target.selectedIndex].getAttribute('data-code') + ' ';

    setProfileData({
      ...profileData,
      [field]: evt.target.value,
      celular: codeFone,
    })

  }

  const handleBlurCep = () => {
    fetch(`https://viacep.com.br/ws/${profileData.cep}/json/`)
      .then(response => {
        return response.json();
      }).then(data => {
        setProfileData({
          ...profileData,
          cidade: data.localidade,
          estado: data.uf,
          bairro: data.bairro,
          endereco: data.logradouro
        })
      })
  }

  const themeButtonPassword = {
    bg: color.primary,
    fc: '#ffffff',
    bdc: color.primary,
    bdc_hover: color.primary,
  }

  const indexForOptionsSelectCoutries = {
    value: 'iso_alpha2',
    label: 'name'
  }
  const dataAttrForOptionsSelectCoutries = [
    {
      name: 'data-code',
      idx: 'calling_code'
    },
  ]
  const indexForOptionsSelectState = {
    value: 'value',
    label: 'label'
  }
  return (
    <TemplateHome>
      <Section>
        <h1>Meu Perfil</h1>

        <WrapForm>
          <div className="left-side">
            <Photo avatar={profileData.avatar ? profileData.avatar_url : DefaultAvatar}>
              <div className="bg"></div>
              <span>Trocar foto</span>
              <input type='file' id='profilePhoto' onChange={e => handlePhotoUser(e)} />
            </Photo>
            {profileData.new_avatar && profileData.new_avatar !== 'remove' && <MsgResponse msg="Foto em modo de pré-visualização. Clique no botão Atualizar para efetivar a troca." theme="alert" />}
            {profileData.new_avatar === 'remove' && <MsgResponse msg="Clique no botão Atualizar para efetivar a remoção." theme="alert" />}
            {profileData.avatar &&
              <div className="text-center">
                <RemovePhoto onClick={() => handleRemovePhoto()}>Remover foto</RemovePhoto>
              </div>
            }

            <hr />

            <WrapChangePassContact>
              <Button to='/perfil/trocar-senha' link className="button-nav-change-pass" theme={themeButtonPassword}>Trocar senha</Button>

              <p>
                Precisando de alguma ajuda com seu curso ou algum outro assunto?
                <Link to='/suporte'>Clique aqui e fale conosco.</Link>
              </p>

              <hr />

              <p>
                <Link to='/historico-compras'>Acessar histórico de compras.</Link>
              </p>
            </WrapChangePassContact>

          </div>
          <div className="right-side">

            {
              warningCompleteProfile && <MsgResponse msg='Você deve completar seu cadastro para ter acesso à plataforma!' theme="alert" />
            }
            <h3>Meus dados</h3>

            <WrapOtherCountry isBrazil={isBrazil} handleChangeFieldsCountry={handleChangeFieldsCountry} />

            {
              !isBrazil &&
              <>
                <Label htmlFor='fm_pais'>País:</Label>
                <Select
                  id='fm_pais'
                  data={countries}
                  indexOption={indexForOptionsSelectCoutries}
                  dataOption={dataAttrForOptionsSelectCoutries}
                  onChange={handleChangeCountry('pais')}
                  value={profileData.pais}
                />
              </>
            }

            <Label htmlFor='fm_nome'>Nome:</Label>
            <Input value={profileData.nome} onChange={handleFormFill('nome')} id='fm_nome' />
            {
              isBrazil ?
                <Label htmlFor='fm_cpf'>CPF:</Label>
                :
                <Label htmlFor='fm_cpf'>Documento de identificação:</Label>
            }

            {
              isBrazil ?
                <Input value={profileData.cpf} onChange={handleFormFill('cpf')} id='fm_cpf' mask='999.999.999-99' />
                :
                <Input value={profileData.cpf} onChange={handleFormFill('cpf')} id='fm_cpf' />
            }
            <Label htmlFor='fm_email'>E-mail:</Label>
            <Input value={profileData.email} onChange={handleFormFill('email')} id='fm_email' readOnly disabled />
            <Label htmlFor='fm_celular'>Celular:</Label>
            {
              isBrazil ?
                <Input value={profileData.celular} onChange={handleFormFill('celular')} id='fm_celular' mask='+55 (99) 99999-9999' />
                :
                <Input value={profileData.celular} onChange={handleFormFill('celular')} id='fm_celular' />
            }
            <Label htmlFor='fm_empresa'>Empresa:</Label>
            <Input value={profileData.empresa} onChange={handleFormFill('empresa')} id='fm_empresa' />
            {
              isBrazil ?
                <Label htmlFor='fm_cep'>CEP:</Label>
                :
                <Label htmlFor='fm_cep'>Código postal:</Label>
            }

            {
              isBrazil ?
                <Input value={profileData.cep} onChange={handleFormFill('cep')} id='fm_cep' mask='99999-999' onBlur={() => handleBlurCep()} />
                :
                <Input value={profileData.cep} onChange={handleFormFill('cep')} id='fm_cep' />
            }

            <Label htmlFor='fm_estado'>Estado:</Label>
            {
              isBrazil ?
                <Select id='fm_estado' data={br_states} indexOption={indexForOptionsSelectState} onChange={handleFormFill('estado')} value={profileData.estado} />
                :
                <Input value={profileData.estado} onChange={handleFormFill('estado')} id='fm_estado' />
            }
            <div className="row row-half">
              <div className="col-sm-6 col-half">
                <Label htmlFor='fm_cidade'>Cidade:</Label>
                <Input value={profileData.cidade} onChange={handleFormFill('cidade')} id='fm_cidade' />
              </div>
              <div className="col-sm-6 col-half">
                <Label htmlFor='fm_bairro'>Bairro:</Label>
                <Input value={profileData.bairro} onChange={handleFormFill('bairro')} id='fm_bairro' />
              </div>
              <div className="col-sm-9 col-half">
                <Label htmlFor='fm_endereco'>Endereço:</Label>
                <Input value={profileData.endereco} onChange={handleFormFill('endereco')} id='fm_endereco' />
              </div>
              <div className="col-sm-3 col-half">
                <Label htmlFor='fm_numero'>Número:</Label>
                <Input value={profileData.numero} onChange={handleFormFill('numero')} id='fm_numero' />
              </div>
              <div className="col-sm-12 col-half">
                <Label htmlFor='fm_complemento'>Complemento:</Label>
                <Input value={profileData.complemento} onChange={handleFormFill('complemento')} id='fm_complemento' />
              </div>
            </div>

            {error && <MsgResponse msg={error} theme="error" />}
            {success && <MsgResponse msg={success} theme="success" />}

            <br />

            <Button onClick={() => handleUpdateUser()}>Atualizar</Button>
          </div>
        </WrapForm>
      </Section>
    </TemplateHome>
  )
}
export default Profile
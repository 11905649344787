import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import Button from '~/components/form/Button'
import moment from "moment";

const ItemListHistoric = ({ data }) => {

  const id = data.idhistorico_cursos;
  const title_course = data.cursos.titulo;
  const day_purchase = moment(data.pagamentos[0].created_at).format('DD');
  const month_purchase = moment(data.pagamentos[0].created_at).format('MMM');
  const year_purchase = moment(data.pagamentos[0].created_at).format('YYYY');
  const date_purchase = `${day_purchase} de ${month_purchase} de ${year_purchase}`;

  const value = data.pagamentos[0].valor ? data.pagamentos[0].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'Grátis';

  return (
    <tr>
      <td><AiOutlineShoppingCart size={20} /></td>
      <td>{title_course}</td>
      <td>{date_purchase}</td>
      <td>{value}</td>
      <td><Button link to={`/historico-compras/${id}`}>Recibo</Button></td>
    </tr>
  )
}

export default ItemListHistoric
import React from 'react'

// style
import { Item } from './style'

// icons
import { FaCheckCircle } from 'react-icons/fa'

// functions
import { stripSlashes } from '~/services/functions'

const ModuleItem = (props) => {

  const { dataModule, idActive, moduleClick, ClassMenu } = props

  return (
    <Item
      status={dataModule.status}
      active={idActive === dataModule.idhistorico_modulos}>

      <button type='button' onClick={() => moduleClick(dataModule.idhistorico_modulos)}>
        <span className='text'>
          {dataModule.modulos.subtitulo && <span className='sub-title'>{stripSlashes(dataModule.modulos.subtitulo)}</span>}
          {stripSlashes(dataModule.modulos.titulo)}
        </span>

        {dataModule.status === 11 && (
          <span className='icon-finished'>
            <FaCheckCircle />
          </span>
        )}
      </button>
      {ClassMenu}
    </Item>
  )
}
export default ModuleItem
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

// Context
import { AuthContext } from '~/hooks/auth'

// elements
import LeftSidebar from '~/elements/LeftSideBar'
import RightSidebar from '~/elements/RightSideBar'
import AlertRemoteUser from '~/components/AlertRemoteUser'

// functions
import { createCookie } from '~/services/functions'

// components
import LightboxAlert from '~/components/LightboxAlert'

const TemplateHome = ({ children }) => {

  const auth = useContext(AuthContext);

  const [lightboxAlert, setLightboxAlert] = useState(auth.user.primeiro_acesso);
  const [redirectProfile, setRedirectProfile] = useState(false);

  useEffect(() => {
    setLightboxAlert(auth.user.primeiro_acesso === 1 ? true : false)
  }, [auth.user])

  const titleAlert = '<h2 class="tts-alert">Aviso</h2>';
  const msgAlert = `<p>Olá, estamos felizes com seu primeiro acesso. Aproveite para conferir se você registrou o seu nome corretamente. Após concluir o curso, o certificado será emitido para este nome e não poderá ser alterado.</p><p><strong>${auth.user.nome}</strong></p>`;

  const handdleCloseModalAlert = (pageRedirect) => {
    setLightboxAlert(false);
    auth.user.primeiro_acesso = 0;
    createCookie('@ProordemOnline:user', JSON.stringify(auth.user))

    if (pageRedirect) {
      setRedirectProfile(true)
    }
  }

  if (redirectProfile) {
    return <Redirect to='/perfil' />
  }

  return (
    <div className="full-container">
      <LeftSidebar />
      {auth.user.remoto && <AlertRemoteUser />}
      <div className="main-content-offset">
        <div className="main-content">
          {children}
        </div>
      </div>
      <RightSidebar />
      {lightboxAlert && <LightboxAlert setLightboxAlert={handdleCloseModalAlert} title={titleAlert} msg={msgAlert} closable={false} />}
    </div>
  )
}
export default TemplateHome
import styled from 'styled-components'

export const Container = styled.div`
  display: inline-block;
  width: 465px;
  max-width: 100%;
  padding: 10px;
  cursor: pointer;

  img{
    max-width: 100%;
  }
`
import React from 'react'

// styles
import { WrapListTable } from './style'

// rest
import { LoadingPurchasesHistoric } from '~/components/Loading'
import ItemListHistoric from './ItemListHistoric'

const List = ({ historic }) => {

  return (
    <WrapListTable>
      <div className='table-responsive'>
        <table className="table table-list">
          <thead>
            <tr>
              <th></th>
              <th>Curso</th>
              <th>Data de compra</th>
              <th>Preço total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>

            {
              historic.loading ?
                <tr>
                  <td colSpan={5}>
                    <LoadingPurchasesHistoric num={3} />
                  </td>
                </tr>
                : historic.data.length > 0 ?
                  historic.data.map((item, index) => <ItemListHistoric key={index} data={item} />)
                  :
                  <tr>
                    <td colSpan={5}>Nenhum historico encontrado!</td>
                  </tr>
            }
          </tbody>
        </table>
      </div>
    </WrapListTable>
  )
}

export default List
import React from 'react'
import { TextareaStyled } from './style'

const Textarea = ({ ...rest }) => {
  return (
    <div className="form-group">
      <TextareaStyled {...rest} />
    </div>
  )
}
export default Textarea
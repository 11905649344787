import React from 'react';

// templates
import TemplateHome from '~/templates/Home'

const NoPageFound = () => {

  return (
    <TemplateHome>
      <h1>404</h1>
      <h3 className='fw-400'>Não foi possível encontrar essa página!</h3>
    </TemplateHome>
  )
}
export default NoPageFound
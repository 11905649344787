import styled from 'styled-components'
import { font, color, media } from '~/styles/variables'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;

  ${media.xl}{
    margin-bottom: 60px;
  }

  h4{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 25px 0;
  }
`
export const Menu = styled.ul`
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 230px;
  overflow: auto;
  box-sizing: content-box;
  padding-right: 14px;
  width: 100%;
`
export const Item = styled.li`
  display: block;

  &:not(:last-child){
    margin-bottom: 5px;

    ${media.xl}{
      margin-bottom: 15px;
    }
  }

  > div{
    display: inline-flex;
    width: 100%;
    font-family: ${font.second};
    font-size: 12px;
    color: ${color.second};
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    line-height: 1;
    padding: 5px 5px 5px 2px;
    cursor: pointer;

    ${media.lg}{
      font-size: 13px;
    }
    ${media.xl}{
      font-size: 14px;
    }

    span{
      display: flex;
      &.icon{
        display: flex;
        margin-right: 5px;

        ${media.lg}{
          margin-right: 10px;
        }
        ${media.xl}{
          margin-right: 15px;
        }
      }

      &.icon, svg{
        width: 17px;
        height: 17px;

        ${media.lg}{
          width: 22px;
          height: 22px;
        }
        ${media.xl}{
          width: 27px;
          height: 27px;
        }
      }
    }

    &:hover{
      background: #FFFFFF;
      color: ${color.primary};
    }
  }
`
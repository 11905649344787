import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes'
import { GoogleOAuthProvider } from '@react-oauth/google';

// styles
import GlobalStyle from './styles/global'
import HelpersStyle from './styles/helpers'

import { AuthProvider } from './hooks/auth'

function App() {
  return (
    // clientId teste: 1062351796810-406rt4j0h8f0u1bjctql40gochg519jr.apps.googleusercontent.com
    <Router>
      <AuthProvider>
        <GoogleOAuthProvider clientId="995886283291-57ojtt9dfe3e3jlou4nivcvcv0bi28d1.apps.googleusercontent.com">
          <Routes />
        </GoogleOAuthProvider>
      </AuthProvider>

      <GlobalStyle />
      <HelpersStyle />
    </Router>
  );
}

export default App;

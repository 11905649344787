import React from 'react'

// style
import { Container, Msg, MsgAlert, MsgError, MsgSuccess } from './style'

const MsgResponse = ({ msg, theme, width }) => {

  if (theme === 'alert') {
    return (
      <Container>
        <MsgAlert width={width}>
          {msg}
        </MsgAlert>
      </Container>
    )
  }
  if (theme === 'error') {
    return (
      <Container>
        <MsgError width={width}>
          {msg}
        </MsgError>
      </Container>
    )
  }
  if (theme === 'success') {
    return (
      <Container>
        <MsgSuccess width={width}>
          {msg}
        </MsgSuccess>
      </Container>
    )
  }

  return (
    <Container>
      <Msg width={width}>
        {msg}
      </Msg>
    </Container>
  )
}
export default MsgResponse
import styled from 'styled-components'

// components -----

export const Alert = styled.div`
  border: 10px solid #bf4444;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;
`
export const AlertMessage = styled.div`
  background: #bf4444;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;
`
export const AlertButton = styled.button`
  float: right;
  background: #01b0ea;
  border: none;
  border-radius: 5px;
  font-size: 13px;
  padding: 1px 25px;
  pointer-events: all;
`



import styled, { css } from 'styled-components'
import InputMask from "react-input-mask";

export const InputStyledCss = css`
  width: 100%;
  max-width: 100%;
  font-size: 15px;
  height: 45px;
  padding: 10px 15px;
  border: 1px solid #707070;
  border-radius: 8px;
  outline: none;

  &[readonly] {
    background: #f1f1f1;
  }
  &[disabled] {
    cursor: not-allowed;
  }
`
export const InputStyled = styled.input`
  ${InputStyledCss}
`
export const InputMaskStyled = styled(InputMask)`
  ${InputStyledCss}
`
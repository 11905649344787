import React, { useContext } from 'react'

// Context
import { AuthContext } from '~/hooks/auth'

// conf
import { BASE_URL_SITE } from '~/services/conf'

// style
import {
  AlertButton,
  AlertMessage,
  Alert,
} from './style'

const AlertRemoteUser = () => {

  const auth = useContext(AuthContext)

  const handleLogout = () => {
    auth.signOutUser(BASE_URL_SITE + "/jgadmin");
  }

  return (
    <Alert> <AlertMessage>ADMINISTRADOR SIMULANDO ACESSO DE ALUNO <AlertButton onClick={() => handleLogout()}>Voltar para o painel</AlertButton> </AlertMessage> </Alert>
  )
}

export default AlertRemoteUser
import React, { useEffect, useState } from 'react'

import QuestionItem from './QuestionItem'
import AnswerItem from './AnswerItem'

const Questions = ({ questionsData, handleUserAnswer }) => {

  const [questionsComponent, setQuestionsComponent] = useState(null)

  useEffect(() => {
    setQuestionsComponent(
      questionsData.map(itemQuestion => {

        const answerComponent = itemQuestion.respostas.map(itemAnswer => {
          return (
            <AnswerItem
              key={itemAnswer.idrespostas}
              dataAnswer={itemAnswer}
              idQuestion={itemQuestion.idtestes}
              handleUserAnswer={handleUserAnswer} />
          )
        })

        return <QuestionItem key={itemQuestion.idtestes} answersComponent={answerComponent} dataQuestion={itemQuestion} />
      })
    )
  }, [handleUserAnswer, questionsData])

  return questionsComponent
}

export default Questions
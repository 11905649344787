import React from 'react'
import { Container } from './style'

const WrapOtherCountry = ({ isBrazil, handleChangeFieldsCountry }) => {
  return (
    <Container>
      {
        isBrazil ?
          <span className="span-other-country">
            Se você não mora no Brasil, <span className="link" onClick={() => handleChangeFieldsCountry()}>clique aqui</span>
          </span>
          :
          <span className="span-brazil">
            Se você mora no Brasil, <span className="link" onClick={() => handleChangeFieldsCountry()}>clique aqui</span>
          </span>
      }
    </Container>
  )
}
export default WrapOtherCountry;
import styled, { css, keyframes } from 'styled-components'
import { color, media } from '~/styles/variables'
import * as mixin from '~/styles/mixins'
import { lighten } from 'polished'

export const blinkEffect = keyframes`
  50% {
    background-color: #f1f1f1
  }
`
export const blink_primaryColor = keyframes`
  50% {
    background-color: ${lighten(0.3, color.primary)};
  }
`
export const blinkBackground = css`
  background: #d4d4d4;
  animation: 1s ${blinkEffect} ease-out infinite;
`
export const blinkBackgroundPrimaryColor = css`
  background-color: ${lighten(0.1, color.primary)};
  animation: 1s ${blink_primaryColor} ease-out infinite;
`

// components -----

export const Avatar = styled.div`
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
  position: relative;

  .wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${blinkBackground};
  }
`

export const CourseItem = styled.div`
  display: inline-block;
  width: 300px;
  height: 257px;
  max-width: 100%;
  position: relative;
  padding: 15px;
  overflow: hidden;
  border-radius: 8px;

  .wrap{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    ${blinkBackground};
  }
`
export const ModuleItem = styled.div`
  display: inline-flex;
  width: 100%;
  height: 70px;
  margin-bottom: 15px;

  ${media.lg}{
    height: 80px;
  }

  ${media.xl}{
    height: 90px;
  }

  .wrap{
    width: 100%;
    height: 100%;
    ${blinkBackground};
  }
`
export const CourseTitle = styled.div`
  width: 300px;
  max-width: 100%;
  ${blinkBackground};

  .wrap{
    ${mixin.fs_head(40)};
    font-weight: 900;
    opacity: 0;
    visibility: 0;
  }
`
export const CourseContent = styled.div`
  width: 100%;

  .video{
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    margin-bottom: 30px;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      ${blinkBackground};
    }
  }

  .title{
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    ${blinkBackground};
  }

  .paragraph{
    width: 100%;
    height: 20px;
    margin-bottom: 15px;
    ${blinkBackground};
  }
`
export const TestContent = styled.div`
  width: 100%;

  .title{
    width: 100%;
    height: 66px;
    margin-bottom: 30px;
    ${blinkBackground};
  }

  .paragraph{
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
    ${blinkBackground};
  }

  ul{
    display: block;
    list-style: none;
    padding: 0;
    margin: 35px 0 0 0;
    
    li{
      display: block;
      margin-bottom: 15px;

      span{
        display: block;
        width: 200px;
        max-width: 100%;
        height: 30px;
        ${blinkBackground};
      }

      ul.answer{
        padding-left: 25px;
        margin: 0 0 25px 0;

        li{
          height: 20px;
          margin: 10px 0;

          span{
            height: 20px;
          }
        }
      }
    }
  }
`
export const MaterialItem = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  ${blinkBackground};
`
export const CertificateItem = styled.div`
  display: inline-block;
  position: relative;
  width: 445px;
  max-width: 100%;
  margin: 15px;
  padding-bottom: 20%;
  cursor: pointer;
  ${blinkBackground};
`
export const WrapTimer = styled.div`
  display: inline-block;
  width: 103px;
  height: 12px;
  ${blinkBackgroundPrimaryColor};
`
export const PurchasesHistoricItem = styled.div`
  display: inline-block;
  width: 100%;
  height: 56.5px;
  mix-blend-mode: 1px;
  ${blinkBackground};
`
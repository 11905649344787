import styled from 'styled-components'
import { media } from '~/styles/variables'

export const Section = styled.section`
  width: 100%;
  text-align: center;

  ${media.sm}{
    text-align: left;
  }
`
import styled from 'styled-components'
import { color, media } from '~/styles/variables'

// grid
import { gridSidebarCourse } from '~/styles/gridSystem'
import { lighten } from 'polished'

export const Container = styled.aside`
  display: flex;
  flex-direction: column;
  ${gridSidebarCourse}
  overflow: hidden;

  ${media.sm}{
    position: fixed;
    bottom: 0;
  }
`
export const WrapProgressBar = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
  font-size: 10px;
  font-weight: 700;
  color: ${lighten(0.1, color.second)};
  background-color: #f1f1f1;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;

  span{
    position: relative;
    z-index: 2;
  }

  .progressBar{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    background-color: ${color.primary};
  }
`
export const ProgressBar = styled.aside`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${props => props.percentage}%;
  background-color: ${color.primary};
  transition: all .4s ease-in-out;
`
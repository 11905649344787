// LOCALHOST
// export const BASE_URL_SITE = 'http://localhost/proordemonline/'
// export const BASE_URL_API = 'http://localhost:3333/' // URL API
// export const COOKIE_DOMAIN = ''

// PRODUCTION
export const BASE_URL_SITE = 'https://proordemonline.com.br/'
export const BASE_URL_API = 'https://api.proordemonline.com.br/' // URL API
export const COOKIE_DOMAIN = '.proordemonline.com.br'

// BOTHS
export const MINIMUM_VIDEO_PLAYED = 80 // percentage
export const BLOCK_BY_VIDEO_PAYED = true // ativa o bloqueio de aula por tempo assistido
export const media = {
  sm: '@media (min-width: 768px)',
  md: '@media (min-width: 992px)',
  lg: '@media (min-width: 1200px)',
  xl: '@media (min-width: 1400px)',
  max_xs: '@media (max-width: 767px)',
  max_sm: '@media (max-width: 991px)',
  max_md: '@media (max-width: 1199px)',
  max_lg: '@media (max-width: 1399px)'
}
export const color = {
  primary: '#1f406c',
  second: '#008338',
  third: '#878787',
  fourth: '#08a928',
  fith: '#0a641c',
  errorColor: '#b70000',
  alertColor: '#ffbd08',
  successColor: '#5B9A33',
  title: '#1f406c',
  text: '#313131'
}
export const font = {
  primary: '"Fira Sans", sans-serif',
  second: '"Open Sans", sans-serif'
}

/* IMPORTANTE -------------
  Como as sidebars e os headers são fixos e só o conteúdo da página que rola, 
  é necessário criar padding no container principal (.main-content-offset .main-content-offset-course)
  a fim de evitar essas áreas fixas.
  Os paddings e posições fixas do elementos são baseados nas dimensões dos mesmos, por exemplo, 
  se a sidebar da esquerda tiver 146px de largura, o padding do container offset terá 146px na lateral esquerda.
  A fim de melhorar a organização, as grids foram montadas no arquivo gridSystem na pasta styles.
  As grids não são automatizadas, se adicionar um item nos objetos abaixo terá que inserir no gridSystem
*/
export const leftSidebar = {
  xs: {
    width: 45
  },
  sm: {
    width: 85
  },
  lg: {
    width: 100
  },
  xl: {
    width: 146
  },
}
export const rightSidebar = {
  sm: {
    width: 180
  },
  lg: {
    width: 210
  },
  xl: {
    width: 280
  }
}
export const headerCourse = {
  xs: {
    height: 85,
  },
  sm: {
    height: 85,
  },
  lg: {
    height: 120,
  },
  xl: {
    height: 155,
  },
}
export const sidebarCourse = {
  sm: {
    width: 200,
  },
  lg: {
    width: 280,
  },
  xl: {
    width: 390,
  }
}
export const leftOffsetContentCourse = {
  xs: {
    offset: leftSidebar.xs.width,
  },
  sm: {
    offset: leftSidebar.sm.width + sidebarCourse.sm.width,
  },
  lg: {
    offset: leftSidebar.lg.width + sidebarCourse.lg.width,
  },
  xl: {
    offset: leftSidebar.xl.width + sidebarCourse.xl.width,
  }
}
export const paddingGrid = {
  xs: {
    tb: 15, // top / bottom
    lr: 15  // left / right
  },
  sm: {
    tb: 20, // top / bottom
    lr: 20  // left / right
  },
  lg: {
    tb: 35, // top / bottom
    lr: 30  // left / right
  },
  xl: {
    tb: 50, // top / bottom
    lr: 60  // left / right
  },
}
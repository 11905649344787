import styled from 'styled-components'
import { color } from '~/styles/variables'

export const Container = styled.div`
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 84%;
  border-radius: 8px;
  background: ${color.primary};
  display: flex;
  border: 1px solid ${color.primary};

  .icon {
    background: #ffffff;
    color: ${color.primary};
    font-size: 0;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    border-radius: 7px;

    svg {
      weight: 30px;
      line-height: 1;
      font-size: 30px;
    }
  }

  .desc {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    flex-direction: column;

    span {
      color: #ffffff;
      text-align: center;
      line-height: 1;

      &.s-label {
        display: block;
        font-size: 11px;
        margin-bottom: 3px;
      }
      &.timer {
        display: flex;
        flex-direction: row;
        font-size: 17px;
        font-weight: 700;

        span.s-day{
          margin-right: 5px;
        }
      }
    }
  }
  
`
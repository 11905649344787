import React from 'react'

//style
import { WrapInputAnswered, InputAnswered } from './style'

// components
import InputRadio from '~/components/form/InputRadio'

// icons
import { FaCheckCircle } from 'react-icons/fa'

const AnswerItem = ({ dataAnswer, idQuestion, handleUserAnswer }) => {

  // se houver o índice 'resposta_usuario' nos dados das respostas quer dizer que o usuário enviou o formulário,
  // então mostra o resultado 
  if ('resposta_usuario' in dataAnswer) {

    const rightAnswer = (dataAnswer.correta && dataAnswer.resposta_usuario) ? true : false

    return (
      <WrapInputAnswered>
        <InputAnswered
          key={dataAnswer.idrespostas}
          label={dataAnswer.resposta}
          nameRadio={`answer${idQuestion}`}
          correct={dataAnswer.correta}
          userAnswer={dataAnswer.resposta_usuario}
          rightAnswer={rightAnswer}
          disabled />
        {
          rightAnswer &&
          <div className='wrap-correct-answer'>
            <span>(correto)</span>
            <FaCheckCircle size={17} />
          </div>
        }
      </WrapInputAnswered>
    )

  } else {
    return (
      <InputRadio
        key={dataAnswer.idrespostas}
        label={dataAnswer.resposta}
        nameRadio={`answer${idQuestion}`}
        onClick={() => handleUserAnswer(idQuestion, dataAnswer.idrespostas)} />
    )
  }
}
export default AnswerItem
import React, { useEffect, useState } from 'react'

// styles
import { Container } from './style'

// font icon
import { FaRegClock } from "react-icons/fa";

// functions
import { timerBetweenDates } from '~/services/functions'

// loading
import { LoadingTimer } from '~/components/Loading'

const Timer = ({ fim_promo }) => {

  const [day, setDay] = useState('');
  const [houre, setHoure] = useState('');
  const [minute, setMinute] = useState('');
  const [second, setSecond] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(function () {
      timerBetweenDates(fim_promo, setDay, setHoure, setMinute, setSecond, setLoading)
    }, 1000);
    return () => clearInterval(interval);
  }, [fim_promo])

  return (
    <Container className='wrap-timer'>
      <div className="icon"><FaRegClock /></div>
      <div className="desc">
        <span className="s-label">PROMO TERMINA EM:</span>
        <span className="timer">
          {
            loading
              ?
              <LoadingTimer />
              :
              <>
                {day && day > 0 && <span className="s-day">{day}</span>}
                {houre && <span>{houre}</span>}
                {minute && <span>{minute}</span>}
                {second && <span>{second}</span>}
              </>
          }
        </span>
      </div>
    </Container>
  )
}
export default Timer
import React, { useState } from 'react'

// style
import { Container, Certificate, Close, WrapButtonShare } from './style'

// image
import close from '~/assets/icons/close.svg'

// components
import Button from '~/components/form/Button'

const Lightbox = ({ content, setLightboxCertificate }) => {

  const [showMsgRes, setShowMsgRes] = useState(false)

  const handleDownload = () => {
    fetch(content.pdf.url)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = content.pdf.name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Ops! Arquivo não encontrado'));
  }

  const copyToClipboard = () => {
    const textField = document.createElement('textarea')
    textField.innerText = content.img
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    setShowMsgRes(true)

    setTimeout(() => {
      setShowMsgRes(false)
    }, 2000);
  };

  return (
    <Container >
      <div className="overlay-opacity" onClick={() => setLightboxCertificate(false)}></div>

      <div className="box">
        <Close onClick={() => setLightboxCertificate(false)}>
          <img src={close} alt="Fechar" />
        </Close>
        {
          content.img &&
          <>
            <div className="content">
              <Certificate certificate={content.img}></Certificate>
            </div>
            <div className="footer">
              <Button onClick={() => handleDownload()}>Download</Button>

              <WrapButtonShare>
                {showMsgRes && <div className="msgResponse">Link de compartilhamento copiado!</div>}
                <Button onClick={() => copyToClipboard()}>Compartilhar</Button>
              </WrapButtonShare>
            </div>
          </>
        }
      </div>

    </Container>
  )
}
export default Lightbox
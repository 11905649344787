import React from 'react'

// style
import {
  Avatar,
  CourseItem,
  ModuleItem,
  CourseTitle,
  CourseContent,
  TestContent,
  MaterialItem,
  CertificateItem,
  WrapTimer,
  PurchasesHistoricItem
} from './style'

export const LoadingAvatar = () => {
  return <Avatar><div className="wrap"></div></Avatar>
}

export const LoadingMainTitle = () => {
  return <CourseTitle><div className="wrap">Loading...</div></CourseTitle>
}

export const LoadingCourseItem = ({ num }) => {
  if (!num) {
    return <CourseItem><div className="wrap"></div></CourseItem>
  } else {
    let component = [];
    for (let i = 0; i < num; i++) {
      component[i] = <CourseItem key={i}><div className="wrap"></div></CourseItem>
    }
    return component
  }
}

export const LoadingModuleItem = ({ num }) => {
  if (!num) {
    return <ModuleItem><div className="wrap"></div></ModuleItem>
  } else {
    let component = [];
    for (let i = 0; i < num; i++) {
      component[i] = <ModuleItem key={i}><div className="wrap"></div></ModuleItem>
    }
    return component
  }
}

export const LoadingCourseContent = () => {
  return (
    <CourseContent>
      <div className="video"></div>
      <div className="title"></div>
      <div className="paragraph"></div>
      <div className="paragraph"></div>
      <div className="paragraph"></div>
      <div className="paragraph"></div>
    </CourseContent>
  )
}

export const LoadingTestContent = () => {
  return (
    <TestContent>
      <div className="title"></div>
      <div className="paragraph"></div>
      <div className="paragraph"></div>
      <div className="paragraph"></div>
      <ul className="test">
        <li>
          <span></span>
          <ul className="answer">
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
          </ul>
        </li>
        <li>
          <span></span>
          <ul className="answer">
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
          </ul>
        </li>
        <li>
          <span></span>
          <ul className="answer">
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
          </ul>
        </li>
      </ul>

    </TestContent>
  )
}

export const LoadingMaterialList = ({ num }) => {
  if (!num) {
    return <MaterialItem></MaterialItem>
  } else {
    let component = [];
    for (let i = 0; i < num; i++) {
      component[i] = <MaterialItem key={i}></MaterialItem>
    }
    return component
  }
}

export const LoadingCertificates = ({ num }) => {
  if (!num) {
    return <CertificateItem></CertificateItem>
  } else {
    let component = [];
    for (let i = 0; i < num; i++) {
      component[i] = <CertificateItem key={i}></CertificateItem>
    }
    return component
  }
}
export const LoadingTimer = () => {
  return <WrapTimer></WrapTimer>
}

export const LoadingPurchasesHistoric = ({ num }) => {
  if (!num) {
    return <PurchasesHistoricItem></PurchasesHistoricItem>
  } else {
    let component = [];
    for (let i = 0; i < num; i++) {
      component[i] = <PurchasesHistoricItem key={i}></PurchasesHistoricItem>
    }
    return component
  }
}
import styled, { css } from 'styled-components'
import { color, font } from '~/styles/variables'
import { lighten } from 'polished'

export const Container = styled.div`
  display: block;
`
const MsgBase = css`
  display: inline-block;
  padding: 10px;
  line-height: 1;
  font-family: ${font.primary};
  background: #f1f1f1;
  border: 1px solid #CCCCCC;
  color: ${color.second};
  margin: 15px 0;
  border-radius: 4px;
  text-align: center;
`

export const Msg = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
`
export const MsgAlert = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
  background: ${lighten(0.45, color.alertColor)};
  border-color: ${color.alertColor};
  color: ${color.alertColor};
`
export const MsgError = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
  background: ${lighten(0.5, color.errorColor)};
  border-color: ${color.errorColor};
  color: ${color.errorColor};
`
export const MsgSuccess = styled.div`
  ${MsgBase};
  width: ${props => props.width ? props.width : 'auto'};
  background: ${lighten(0.5, color.successColor)};
  border-color: ${color.successColor};
  color: ${color.successColor};
`

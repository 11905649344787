import React from 'react'
import { LabelStyled } from './style'

const Label = ({ children, ...rest }) => {

  return (
    <LabelStyled {...rest}>
      {children}
    </LabelStyled>
  )
}

export default Label
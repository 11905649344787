import styled from 'styled-components'
import { media, color } from '~/styles/variables'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  z-index: 10;

  .overlay-opacity{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    cursor: pointer;
  }

  .box{
    position: relative;
    width: 1300px;
    height: 850px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 30px;
    background: #FFF;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 15px;

    ${media.md}{
      padding: 40px 40px 30px 40px;
    }
    ${media.lg}{
      padding: 70px 70px 40px 70px;
    }

    .content{
      flex: 1;
      position: relative;
    }
    .footer{
      padding-top: 35px;
      text-align: center;

      button{
        margin: 5px 15px;

        ${media.lg}{
          margin: 0 25px;
        }
      }
    }
  }
`
export const Close = styled.div`
  width:30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 5;

  ${media.lg}{
    top: 25px;
    right: 25px;
  }

  img{
    width: 100%;
  }

  &:hover{
    opacity: .8;
  }
`
export const Certificate = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${props => props.certificate});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`
export const WrapButtonShare = styled.div`
  position: relative;
  display: inline-flex;

  .msgResponse{
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    background: ${color.successColor};
    border: 1px solid ${color.successColor};
    position: absolute;
    right: 0;
    bottom: 100%;
    padding: 5px;
    line-height: 1;
    border-radius: 4px;
    margin-bottom: 5px;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: 100%;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid ${color.successColor};
    }
  }
`

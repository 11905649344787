import styled from 'styled-components'

export const SelectStyled = styled.select`
  width: 100%;
  max-width: 100%;
  font-size: 15px;
  height: 45px;
  padding: 10px 15px;
  border: 1px solid #707070;
  border-radius: 8px;
  outline: none;
`

import React from 'react'

// style
import { Container, Close, WrapButtonFoot } from './style'
import { color } from '~/styles/variables'

// image
import close from '~/assets/icons/close.svg'

// components
import Button from '~/components/form/Button'

const LightboxAlert = ({ title, msg, setLightboxAlert, closable = true }) => {

  const themeButton = {
    blue: {
      bg: color.primary,
      fc: '#FFFFFF',
      bdc: color.primary
    },
  }

  return (
    <Container>
      {
        closable ?
          <div className="overlay-opacity" onClick={() => setLightboxAlert()}></div>
          :
          <div className="overlay-opacity"></div>
      }

      <div className="box">
        {
          closable &&
          <Close onClick={() => setLightboxAlert()}>
            <img src={close} alt="Fechar" />
          </Close>
        }
        <div className="content">
          {<div dangerouslySetInnerHTML={{ __html: title }} />}
          {<div dangerouslySetInnerHTML={{ __html: msg }} />}

          <WrapButtonFoot>
            <Button onClick={() => setLightboxAlert('perfil')}>Quero alterar</Button>
            <Button onClick={() => setLightboxAlert()} theme={themeButton.blue}>Está correto</Button>
          </WrapButtonFoot>
        </div>
      </div>

    </Container>
  )
}
export default LightboxAlert
import React, { useContext } from 'react';

// Context
import { AuthContext } from '~/hooks/auth'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section } from './style';

// components
import CourseItem from './CourseItem'
import { LoadingCourseItem } from '~/components/Loading'
import { LoadingMainTitle } from '~/components/Loading'
import Button from '~/components/form/Button'

// rest
import { useGet } from '~/services/rest'

// conf
import { BASE_URL_SITE } from '~/services/conf'

const Home = () => {

  const auth = useContext(AuthContext)

  const listUserCourses = useGet('historicocursos')
  const listRecommendedCourses = useGet('cursos/curso/recomendados')

  return (
    <TemplateHome>
      {
        !auth.user
          ?
          <LoadingMainTitle />
          :
          <h1>Olá {auth.user.nome}!</h1>
      }

      <Section>
        <h2 className="secondColor">Meus Cursos</h2>
        <div className="row">
          {
            listUserCourses.loading ?
              <LoadingCourseItem num={3} />
              :
              listUserCourses.data.length > 0
                ?
                listUserCourses.data.map(item => <CourseItem key={item.idhistorico_cursos} ongoing data={item} />)
                :
                <p>Você não possui nenhum curso ainda.</p>
          }
        </div>
      </Section>

      <Section>
        <div className="row">
          <p className="secondColor">Talvez também possa te interessar:</p>
          {
            listRecommendedCourses.loading ?
              <LoadingCourseItem num={3} />
              :
              listRecommendedCourses.data.length > 0
                ?
                <>
                  {listRecommendedCourses.data.map((item, index) => {
                    if (index <= 6) {
                      return (
                        <CourseItem key={item.idcursos} data={item} />
                      )
                    }
                    return null
                  })}
                  <Button href={`${BASE_URL_SITE}cursos`} link={{ external: true }} className="see-more">Ver todos os cursos</Button>
                </>
                :
                <p>Não encontramos nenhum curso relacionado.</p>
          }
        </div>
      </Section>
    </TemplateHome>
  )
}
export default Home
import styled from 'styled-components'
import { color } from '~/styles/variables'
import { lighten } from 'polished'

// components -----

export const Container = styled.div`
  padding: 20px;
  border-radius: 10px;
  background: ${lighten(0.45, color.alertColor)};
  border: 1px solid ${color.alertColor};
  color: ${color.alertColor};
`
export const ModuleWrap = styled.ul`
  padding: 0;
  list-style: none;
`
export const ModuleItem = styled.li`
  font-weight: 700;
`
export const ClassWrap = styled.ul`
  padding: 0;
  list-style: none;
`
export const ClassItem = styled.li`
  padding-left: 30px;
  margin-bottom: 5px;
  font-weight: 400;
`

import styled from 'styled-components'
import { media, color } from '~/styles/variables'

export const Container = styled.div`
  width: 100%;

  ${media.xl}{
    /* padding-right: 40%; */
  }
`
export const WrapQuestions = styled.div`
  width: 100%;
  margin-top: 55px;
`
export const WrapResults = styled.div`
  width: 100%;
  margin-bottom: 100px;

  h3{
    font-weight: 300;
    color: ${color.third};
    margin: 0 0 5px 0;
  }
  h4{
    font-weight: 700;
    color: #000000;
  
    span{
      font-size: 190%;
    }
  }
`
export const WrapNextClass = styled.div`
  width: 100%;
  margin-top: 50px;

  h3{
    font-weight: 300;
    color: ${color.third};
    margin: 0 0 10px 0;
  }
`

import React from 'react'

// style
import { Container } from './style'

const CertificateItem = ({ data, ...rest }) => {
  return (
    <Container {...rest}>
      <img src={data.certificado_img_url} alt={data.titulo} />
    </Container>
  )
}
export default CertificateItem
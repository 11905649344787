import React from 'react'
import { ButtonStyled, ButtonSpan, LinkButtonStyled, LinkComponentButtonStyled } from './style'

const Button = ({ span, link, children, type, ...rest }) => {

  if (span) {
    return (
      // retorna botão
      <ButtonSpan {...rest}>
        {children}
      </ButtonSpan>
    )
  }
  if (link) {
    if (link.external) {
      return (
        // retorna link comum para urls externas
        <LinkButtonStyled {...rest}>
          {children}
        </LinkButtonStyled>
      )
    } else {
      // retorna component Link do react-router-dom para navegação interna
      return (
        <LinkComponentButtonStyled {...rest}>
          {children}
        </LinkComponentButtonStyled>
      )
    }
  } else {
    return (
      // retorna botão
      <ButtonStyled type={type ? type : 'button'} {...rest}>
        {children}
      </ButtonStyled>
    )
  }

}

export default Button
import React from 'react'
import moment from "moment";

const ItemListHistoric = ({ data }) => {

  const title_course = data.cursos.titulo;
  const parcelas = data.pagamentos[0].parcelas;
  const cupom = data.pagamentos[0].cupons ? data.pagamentos[0].cupons.cupom : '-';
  const desconto = data.pagamentos[0].desconto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  const value = data.pagamentos[0].valor ? data.pagamentos[0].valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'Grátis';
  const total = data.pagamentos[0].total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  const day_purchase = moment(data.pagamentos[0].created_at).format('DD');
  const month_purchase = moment(data.pagamentos[0].created_at).format('MMM');
  const year_purchase = moment(data.pagamentos[0].created_at).format('YYYY');
  const date_purchase = `${day_purchase} de ${month_purchase} de ${year_purchase}`;

  return (
    <>
      <tr>
        <td>{title_course}</td>
        <td>{date_purchase}</td>
        <td>{parcelas}</td>
        <td>{cupom}</td>
        <td>{desconto}</td>
        <td>{value}</td>
      </tr>
      <tr>
        <td colSpan={5} style={{ textAlign: 'right' }}><b>Total pago:</b></td>
        <td>{total}</td>
      </tr>
    </>
  )
}

export default ItemListHistoric
import React from 'react'
import { Link } from 'react-router-dom'

// styles
import { WrapForm, Form } from './style'

// components 
import Input from '~/components/form/Input'
import Label from '~/components/form/Label'
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'

//conf
import { BASE_URL_SITE } from '~/services/conf'

const FormRecoveryPassword = ({ handleFormEmail, handleRecoveryPassword, resRecoveryPassword, email }) => {

  return (
    <WrapForm>

      <h2>Recuperar senha</h2>

      <Form>
        <Label htmlFor="fm_email">E-mail:</Label>
        <Input type="text" id="fm_email" onChange={e => handleFormEmail(e)} value={email} />
        <Link to="/login">Fazer login</Link>
        <div className="text-right">
          <Button type="button" onClick={() => handleRecoveryPassword()}> Recuperar senha</Button>
        </div>
        {
          resRecoveryPassword.error
            ?
            <MsgResponse msg={resRecoveryPassword.error} width='100%' theme='error' />
            :
            resRecoveryPassword.data.sucesso
            &&
            <MsgResponse msg='Uma nova senha foi enviada para seu e-mail' width='100%' theme='success' />
        }
      </Form>

      <div className="text-center">
        <a href={BASE_URL_SITE + 'cadastro'} title="Ainda não tem cadastro? clique aqui">Ainda não tem cadastro? <span>clique aqui</span></a>
      </div>

    </WrapForm>
  )
}

export default FormRecoveryPassword
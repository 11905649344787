import React, { useEffect, useState, useRef } from 'react'
import Player from '@vimeo/player';

// style
import { VideoFrame } from './style'

const Video = ({ idVideo, setPercentagePlayed, historicClass }) => {

  const iframe_video = useRef();
  const [player, setPlayer] = useState(null);
  const [percentagePlayedControl, setPercentagePlayedControl] = useState(historicClass.video_assistido_porcentagem);
  // const [intervalTimeVideo, setIntervalTimeVideo] = useState([]);

  const video_url = `https://player.vimeo.com/video/${idVideo.trim()}`

  // cria instância do player
  useEffect(() => {
    if (iframe_video.current) {
      setPlayer(new Player(iframe_video.current))
    }
  }, [iframe_video])

  useEffect(() => {

    let intervalSeconds = 2;
    let controlIntervalSeconds = intervalSeconds;
    let lastSecond = 0;

    // função que pega o intervalo de tempo assistido para calcular porcentagem
    const handlerGetTimeIntervalPlayed = (data) => {

      const currentSeconds = data.seconds;
      const videoDuration = data.duration;

      // controla atualização dos valores de 2 em 2 segundos
      // pega o segundo atual do video e soma + 2 para comparar se ja chegou
      // segundo parametro valida se o usuario voltou o video, se for o caso ele atualiza tambem
      if ((currentSeconds >= controlIntervalSeconds) || (currentSeconds < lastSecond)) {
        lastSecond = currentSeconds;
        controlIntervalSeconds = currentSeconds + intervalSeconds;

        player.getPlayed().then(played => {
          // setIntervalTimeVideo(played);

          let totalPlayed = 0;

          // perrocrre array de intervalos para somar o tempo assistido
          played.forEach((itemInterval, index, array) => {
            totalPlayed += itemInterval[1] - itemInterval[0];
          })

          // calcula porcentagem assistida
          const percentagePlayed = Math.round((totalPlayed * 100) / videoDuration);

          // se a porcentagem recorrente for maior que ja assistida atualiza os dados, se não, não
          if (percentagePlayed > percentagePlayedControl) {
            setPercentagePlayedControl(percentagePlayed) // controle para este componente
            setPercentagePlayed(percentagePlayed) // controle para o componente da aula
          }

        }).catch(error => {
          console.log('countTimePlayed', error);
        });
      }
    }

    // quando o video encerrar verifica se foi assistido tudo para concluir 100%
    const handlerEndedVideo = (data) => {

      const videoDuration = data.duration;

      player.getPlayed().then(played => {

        let totalPlayed = 0;
        played.forEach((itemInterval, index, array) => {
          totalPlayed += itemInterval[1] - itemInterval[0];
        })

        if (totalPlayed === videoDuration) {
          setPercentagePlayedControl(100)
          setPercentagePlayed(100)
        }

      }).catch(error => {
        console.log('countTimePlayed', error);
      });
    }

    if (player) {
      player.on('timeupdate', handlerGetTimeIntervalPlayed); // monitora a mudança do tempo do vídeo
      player.on('ended', handlerEndedVideo); // monitora quando o video termina
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player])


  return (
    <VideoFrame>
      <iframe src={video_url} allow='autoplay; fullscreen' title="Vídeo aula" ref={iframe_video}></iframe>
    </VideoFrame>
  )
}
export default Video
import React from 'react'

// style
import { Item } from './style'

// internal component
import { IconMaterial } from './IconMaterial'

const MenuItem = ({ dataMaterial, dataCourse }) => {

  const type = dataMaterial.arquivo.split('.').pop();

  const handleDownload = () => {
    fetch(dataMaterial.arquivo_url)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = dataMaterial.arquivo;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('Ops! Arquivo não encontrado'));
  }

  return (
    <Item>
      <div onClick={() => handleDownload()}>
        <span className='icon'>
          <IconMaterial typeFile={type} />
        </span>
        <span className='text'>{dataMaterial.titulo ? dataMaterial.titulo : dataCourse.data[0].aulas.titulo}</span>
      </div>
    </Item>
  )
}
export default MenuItem
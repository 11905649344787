import React from 'react'

// style
import { Container, Menu } from './style'

const MenuMaterial = ({ items }) => {
  return (
    <Container>
      <h4>Materiais de apoio</h4>

      <Menu>
        {items}
      </Menu>
    </Container>
  )
}
export default MenuMaterial
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

// Context
import { AuthContext } from '~/hooks/auth'

// style
import { Container, Header, Content, Footer, Avatar, Menu } from './style'

// images
import iconCursos from '~/assets/icons/cursos.svg'
import iconCertificados from '~/assets/icons/certificados.svg'
import iconSair from '~/assets/icons/sair.svg'

// components
import { LoadingAvatar } from '~/components/Loading'

const LeftSideBar = () => {

  const auth = useContext(AuthContext)

  const handleLogout = () => {
    auth.signOutUser()
    window.location.reload();
  }

  return (
    <Container>
      <Header>
        <Link to="/perfil">
          {
            !auth.user
              ?
              <LoadingAvatar />
              :
              <Avatar avatar={auth.user.avatar} avatar_url={auth.user.avatar_url}>
                <div className="bg"></div>
              </Avatar>
          }
          <h4>Meu Perfil</h4>
        </Link>
      </Header>
      <Content>
        <Menu>
          <ul>
            <li>
              <Link to="/">
                <img src={iconCursos} alt="Meus Cursos" />
                <span className='text'>Meus Cursos</span>
              </Link>
            </li>
            <li>
              <Link to="/certificados">
                <img src={iconCertificados} alt="Certificados" />
                <span className='text'>Certificados</span>
              </Link>
            </li>
          </ul>
        </Menu>
      </Content>
      <Footer>
        <Menu>
          <ul>
            <li>
              <button type="button" onClick={() => handleLogout()}>
                <img src={iconSair} alt="Sair" />
                <span className="text">Sair</span>
              </button>
            </li>
          </ul>
        </Menu>
      </Footer>
    </Container>
  )
}
export default LeftSideBar
import React from 'react'

// style
import { Menu } from './style'

const MenuModule = ({ ModuleItems }) => {

  return (
    <Menu>
      {ModuleItems}
    </Menu>
  )
}
export default MenuModule
import { media } from './variables';

export const fs_head = fs => {

  const percentage = {
    xs: 1.3,
    sm: 1.2,
    md: 1.1,
  }

  return `
    font-size: ${fs / percentage.xs}px;

    ${media.sm} {
      font-size: ${fs / percentage.sm}px;
    }
    ${media.md} {
      font-size: ${fs / percentage.md}px;
    }
    ${media.lg} {
      font-size: ${fs}px
    }
  `
}

export const text = () => {
  return `
    font-size: 14px;
    line-height: 25px;

    &.featured {
      font-size: 16px;
      color: @primaryColor;
    }
  `
}

export const transition = string => {
  return `
    -webkit-transition: ${string};
    -moz-transition: ${string};
    -ms-transition: ${string};
    -o-transition: ${string};
    transition: ${string};
  `
}

export const transform = string => {
  return `
    -webkit-transform: ${string};
    -moz-transform: ${string};
    -ms-transform: ${string};
    -o-transform: ${string};
    transform: ${string};
  `
}
import React, { useState } from 'react';

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section } from './style';

// components
import CertificateItem from './CertificateItem'
import Lightbox from '~/components/Lightbox'
import { LoadingCertificates } from '~/components/Loading'

//rest 
import { useGet } from '~/services/rest'

const Certificates = () => {

  const certificates = useGet('certificados')

  const [lightboxCertificate, setLightboxCertificate] = useState(false)
  const [lightboxCertificatePdf, setLightboxCertificatePdf] = useState(false)

  const handleLightbox = (img, pdfUrl, pdfName) => {
    setLightboxCertificate(img)
    setLightboxCertificatePdf({ url: pdfUrl, name: pdfName })
  }

  const CertificatesComponent = certificates.data.length > 0 && certificates.data.map(item => {
    return (
      <CertificateItem
        key={item.idcertificados}
        onClick={() => handleLightbox(item.certificado_img_url, item.certificado_pdf_url, item.certificado_pdf)} data={item} />
    )
  })

  return (
    <TemplateHome>
      <Section>
        <h1>Meus Certificados</h1>

        <div className="row">
          {
            certificates.data.length === 0 && certificates.loading
              ?
              <LoadingCertificates num={3} />
              :
              certificates.data.length === 0
                ?
                <>
                  <p>Você não possui certificados ainda.</p>
                  <p>Se você concluiu o curso a pouco tempo, aguarde que em breve seu certificado aparecerá aqui.</p>
                </>
                :
                <>
                  {CertificatesComponent}
                  <hr />
                  <p style={{ fontSize: '10px' }}>Caso tenha finalizado seu curso recentemente, aguarde até 48 horas para emissão do certificado.</p>
                </>
          }
        </div>
      </Section>
      {lightboxCertificate && <Lightbox content={{ img: lightboxCertificate, pdf: lightboxCertificatePdf }} setLightboxCertificate={setLightboxCertificate} />}
    </TemplateHome>
  )
}
export default Certificates
import React from 'react'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { ButtonStyled } from './style'
import { useHistory } from "react-router-dom";

const BackButton = () => {
  const history = useHistory();

  return (
    // retorna botão
    <ButtonStyled onClick={() => history.goBack()}>
      <FaLongArrowAltLeft size={15} /> Voltar
    </ButtonStyled>
  )
}

export default BackButton
import React from 'react'
import { InputStyled, InputMaskStyled } from './style'

const Input = ({ mask, ...rest }) => {

  if (mask) {
    return (
      <div className="form-group">
        <InputMaskStyled mask={mask} {...rest} />
      </div>
    )
  }
  return (
    <div className="form-group">
      <InputStyled {...rest} />
    </div>
  )
}

export default Input
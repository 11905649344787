// conf
import { COOKIE_DOMAIN } from './conf'

export const cpf_validation = strCPF => {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;

  for (var i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (var y = 1; y <= 10; y++) Soma = Soma + parseInt(strCPF.substring(y - 1, y)) * (12 - y);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export const clear_mask = value => {
  return value.replace(/[^\w\s]/gi, '').replace(/ /g, '')
}

export const createCookie = (name, value, expires = '') => {
  // cria cookies para manter login entre plataformas

  let dateExpireCookie = '';

  if (expires) {
    dateExpireCookie = typeof expires === 'string' ? new Date(expires) : expires
  } else {
    dateExpireCookie = new Date();
    dateExpireCookie.setDate(dateExpireCookie.getDate() + 1);
    dateExpireCookie.setHours(dateExpireCookie.getHours() + 3);
  }

  let val_cookie = encodeURIComponent(value);
  document.cookie = `${name}=${val_cookie};domain=${COOKIE_DOMAIN};path=/;expires=${dateExpireCookie}`
}
export const getCookie = name => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return null;
}
export const deleteCookie = name => {
  document.cookie = `${name}=;domain=${COOKIE_DOMAIN};path=/;expires==Thu, 01 Jan 1970 00:00:01 GMT;`
}
export const stripSlashes = str => {
  str = str.replace(/\\'/g, '\'');
  str = str.replace(/\\"/g, '"');
  str = str.replace(/\\0/g, '\0');
  str = str.replace(/\\\\/g, '\\');
  return str;
}

export const timerBetweenDates = (fim_promo, setDay, setHoure, setMinute, setSecond, setLoading) => {

  var end_date = new Date(fim_promo);
  var now = new Date();
  var distance = end_date.getTime() - now.getTime();

  if (distance <= 0) {
    // Timer done
    document.location.reload(true);
    return false
  } else {

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (days === 1) {
      days -= 1;
      hours += 24
    }

    // strings ---
    var _days = `${days}D`;
    var _hours = `${hours.toString().padStart(2, "0")}:`;
    var _minutes = `${minutes.toString().padStart(2, "0")}:`;
    var _seconds = seconds.toString().padStart(2, "0");

    setDay(_days);
    setHoure(_hours);
    setMinute(_minutes);
    setSecond(_seconds);
    setLoading(false)
  }
}
import React, { useState, useEffect, useContext } from 'react'

// Context
import { AuthContext } from '~/hooks/auth'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section, WrapForm } from './style'

// components
import Textarea from '~/components/form/Textarea'
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'

// rest
import { usePost } from '~/services/rest'

const Support = () => {

  const auth = useContext(AuthContext)

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const [mensagem, setMensagem] = useState({ mensagem: '' })
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    if (auth.user) {
      const user = Object.assign(auth.user, { usuarios_id: auth.user.idusuarios })
      setUserData(user)
    }
  }, [auth])

  const handleFormFill = field => evt => {
    setMensagem({
      ...mensagem,
      [field]: evt.target.value
    })
  }

  const [resSendSupport, sendSupport] = usePost(`contatos`)

  useEffect(() => {
    if (resSendSupport.error) {
      setError(resSendSupport.error)
    } else if (resSendSupport.data.length > 0 || resSendSupport.data.sucesso) {
      setMensagem({ mensagem: '' })
      setSuccess("Mensagem enviada!")
    }
  }, [resSendSupport])

  const handleSendMessage = async () => {

    setError(null)

    if (
      !mensagem.mensagem
    ) {
      setError('Por favor, digite uma mensagem!')
      return false
    }

    const data = { ...mensagem, ...userData }

    sendSupport(data)
  }

  return (
    <TemplateHome>
      <Section>
        <h1>Suporte</h1>

        <WrapForm>

          <h3>Digite aqui como podemos lhe ajudar</h3>

          <Textarea value={mensagem.mensagem} onChange={handleFormFill('mensagem')} rows="6" />

          {error && <MsgResponse msg={error} theme="error" />}
          {success && <MsgResponse msg={success} theme="success" />}

          <Button onClick={() => handleSendMessage()}>Enviar</Button>
        </WrapForm>

      </Section>
    </TemplateHome>
  )
}

export default Support
import React from 'react'

// style
import { Container } from './style'

// functions
import { stripSlashes } from '~/services/functions'

const Question = ({ dataQuestion, answersComponent }) => {

  return (
    <Container answeredQuestion={dataQuestion.answered !== undefined ? dataQuestion.answered : true}>
      <h4>{<div dangerouslySetInnerHTML={{ __html: stripSlashes(dataQuestion.questao) }} />}</h4>
      <div className="wrap-answers">
        {answersComponent}
      </div>
    </Container>
  )
}
export default Question
import styled from 'styled-components'
import { color } from '~/styles/variables'
import { darken } from 'polished'

export const WrapFinalButtons = styled.div`
  a{
    margin: 5px;
  }
`
export const AlertRepeatTestTest = styled.p`
  background: ${color.alertColor};
  padding: 5px 10px;
  line-height: 1;
  display: inline-block;
  border-radius: 5px;
  color: ${darken(0.3, color.alertColor)};
  font-weight: 700;
  font-size: 16px;
  
`
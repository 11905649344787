import React, { useState, useEffect, useContext } from 'react'
import { Redirect } from 'react-router'
// import RatingStars from "react-rating-stars-component";
import RatingStars from 'react-star-ratings';

// Context
import { AuthContext } from '~/hooks/auth'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section, WrapForm, UserName, WrapRatingStars } from './style'

// components
import Input from '~/components/form/Input'
import Label from '~/components/form/Label'
import Textarea from '~/components/form/Textarea'
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'

// rest
import { usePost, usePut, useGet } from '~/services/rest'

const CourseAvaliation = ({ location }) => {

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const [courseId, setCourseId] = useState(null)
  const [courseName, setCourseName] = useState(null)
  const [redirect, setRedirect] = useState(false)

  const auth = useContext(AuthContext)

  const [avaliationMsg, setAvaliationMsg] = useState({ mensagem: '' })
  const [rating, setRating] = useState({ nota: 0 })
  const [userData, setUserData] = useState({ empresa: '', nome: '' })
  const [userRating, setUserRating] = useState('loading')

  const comentario = useGet(`comentarios/usuario/curso/${courseId}`);

  useEffect(() => {
    if (location.state) {
      setCourseId(location.state.course_id)
      setCourseName(location.state.course_name)
    } else {
      setRedirect(true)
    }
  }, [location.state])

  useEffect(() => {
    if (auth.user) {
      setUserData(auth.user)
    }
  }, [auth.user])

  useEffect(() => {
    if (comentario.data.comentario) {
      setUserRating(comentario.data.comentario);
    } else {
      setUserRating([]);
    }
  }, [comentario.data.comentario])

  const handleFormFillMsg = field => evt => {
    setAvaliationMsg({
      ...avaliationMsg,
      [field]: evt.target.value
    })
  }

  const handleFormFillUser = field => evt => {
    setUserData({
      ...userData,
      [field]: evt.target.value
    })
  }

  const [resSendAvaliation, sendAvaliation] = usePost(`comentarios`)
  const [, updateUser] = usePut(`usuarios`)

  useEffect(() => {
    if (resSendAvaliation.error) {
      setError(resSendAvaliation.error)
    } else if (resSendAvaliation.data.length > 0 || resSendAvaliation.data.sucesso) {
      setSuccess("Avaliação enviada! Aguarde...");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [resSendAvaliation])

  const handleSendMessage = async () => {

    setError(null)
    setSuccess(null)

    if (rating.nota === 0) {
      setError('Por favor, atribua uma nota ao curso!')
      return false
    }
    if (!userData.empresa) {
      setError('Por favor, informe sua empresa!')
      return false
    }

    await sendAvaliation({
      cursos_id: courseId,
      comentario: avaliationMsg.mensagem,
      nota: rating.nota
    })

    await updateUser({ empresa: userData.empresa })
  }

  const handlerRating = (newRating) => {
    setRating({ nota: newRating })
  };

  if (redirect) {
    return <Redirect to="/" />
  }

  if (userRating === 'loading' || comentario.loading) {
    return (
      <TemplateHome>
        <Section>
          <h1>Carregando...</h1>
        </Section>
      </TemplateHome>
    )
  } else if (userRating.length > 0) {
    return (
      <TemplateHome>
        <Section>
          <h1>Avaliar curso</h1>

          <WrapForm>

            <h3>Você ja avaliou o curso: {courseName}.</h3>

            <Label htmlFor='fm_nome'>Nome:</Label>
            <UserName>{userData.nome}</UserName>
            <Label htmlFor='fm_nome'>Nota:</Label>
            <WrapRatingStars>
              <RatingStars
                numberOfStars={5}
                rating={userRating[0].nota}
                starDimension='35px'
                starSpacing="0px"
                starRatedColor="#ffd700"
                starHoverColor="#ffd700"
              />
            </WrapRatingStars>
            <Label htmlFor='fm_empresa'>Empresa:</Label>
            <UserName>{userData.empresa}</UserName>
            {
              userRating[0].comentario &&
              <>
                <Label htmlFor='fm_mensagem'>Avaliação:</Label>
                <UserName>{userRating[0].comentario}</UserName>
              </>
            }
          </WrapForm>

        </Section>
      </TemplateHome>
    )
  } else {
    return (
      <TemplateHome>
        <Section>
          <h1>Avaliar curso</h1>

          <WrapForm>

            <h3>Conte como foi sua experiência com o curso: {courseName}.</h3>

            <Label htmlFor='fm_nome'>Nome:</Label>
            <UserName>{userData.nome}</UserName>
            <Label htmlFor='fm_nome'>Atribua uma nota para o curso (1 a 5):</Label>
            <WrapRatingStars>
              <RatingStars
                numberOfStars={5}
                rating={rating.nota}
                changeRating={handlerRating}
                starDimension='35px'
                starRatedColor="#ffd700"
                starHoverColor="#ffd700"
                starSpacing="0px"
              />
            </WrapRatingStars>
            <Label htmlFor='fm_empresa'>Empresa:</Label>
            <Input value={userData.empresa} onChange={handleFormFillUser('empresa')} id='fm_empresa' />
            <Label htmlFor='fm_mensagem'>Avaliação:</Label>
            <Textarea value={avaliationMsg.mensagem} onChange={handleFormFillMsg('mensagem')} rows="6" id='fm_mensagem' />

            {error && <MsgResponse msg={error} theme="error" />}
            {success && <MsgResponse msg={success} theme="success" />}

            <Button onClick={() => handleSendMessage()}>Enviar</Button>
          </WrapForm>

        </Section>
      </TemplateHome>
    )

  }

}

export default CourseAvaliation
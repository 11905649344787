import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

// Context
import { AuthContext } from '~/hooks/auth'

// Login
import Login from '~/pages/Login'
import RecoveryPassword from '~/pages/Login/RecoveryPassword'

// Home
import Home from '~/pages/Home'

// Course
import Course from '~/pages/Course'

// Certificates
import Certificates from '~/pages/Certificates'

// Certificates
import Profile from '~/pages/Profile'
import ChangePassword from '~/pages/Profile/ChangePassword'

// Suporte
import Support from '~/pages/Support'

// Course Avaliation
import CourseAvaliation from '~/pages/CourseAvaliation'

// 404
import NoPageFound from '~/pages/NoPageFound'
import PurchasesHistoric from '~/pages/PurchasesHistoric'
import PoliticasPrivacidade from '~/pages/PoliticasPrivacidade'

const Routes = () => {

  const auth = useContext(AuthContext)

  if (auth && auth?.user?.cadastro_confirmacao === 0) {
    return (
      <Switch>
        <Route path="/politica-de-privacidade" exact component={PoliticasPrivacidade} />
        <Route path="/login" exact component={Login} />
        <Route path="/login/recuperar-senha" exact component={RecoveryPassword} />

        <PrivateRoute path="/" exact component={Profile} />
        <PrivateRoute path="/curso/:slug/" exact component={Profile} />
        <PrivateRoute path="/certificados/" exact component={Profile} />
        <PrivateRoute path="/perfil/" exact component={Profile} />
        <PrivateRoute path="/perfil/trocar-senha" exact component={Profile} />
        <PrivateRoute path="/suporte" exact component={Profile} />
        <PrivateRoute path="/historico-compras" exact component={Profile} />
        <PrivateRoute path="/historico-compras/:idHistorico" exact component={Profile} />
        <PrivateRoute component={Profile} />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/politica-de-privacidade" exact component={PoliticasPrivacidade} />
      <Route path="/login" exact component={Login} />
      <Route path="/login/recuperar-senha" exact component={RecoveryPassword} />

      <PrivateRoute path="/" exact component={Home} />
      <PrivateRoute path="/curso/:slug/" exact component={Course} />
      <PrivateRoute path="/certificados/" exact component={Certificates} />
      <PrivateRoute path="/perfil/" exact component={Profile} />
      <PrivateRoute path="/perfil/trocar-senha" exact component={ChangePassword} />
      <PrivateRoute path="/suporte" exact component={Support} />
      <PrivateRoute path="/historico-compras" exact component={PurchasesHistoric} />
      <PrivateRoute path="/historico-compras/:idHistorico" exact component={PurchasesHistoric} />
      <PrivateRoute path="/avaliar-curso" exact component={CourseAvaliation} />
      <PrivateRoute component={NoPageFound} />
    </Switch>
  )
}

export default Routes
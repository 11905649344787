import styled from 'styled-components'
import { color } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

export const ButtonStyled = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 5px 15px;
  background: ${color.second};
  color: #FFFFFF;
  border: 1px solid ${color.second};
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  margin-bottom: 15px;
  ${mixin.transition('all 0.1s linear')};

  svg{
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.7;
    color: ${props => props.theme.bdc_hover ? props.theme.bdc_hover : "#FFFFFF"};
  }
`
import styled from 'styled-components'
import { color, media } from '~/styles/variables'

export const Section = styled.section`
  width: 100%;
`
export const WrapForm = styled.div`
    max-width: 100%;

  ${media.md}{
    width: 500px;
  }
  h3{
    font-weight: 300;
    color: ${color.third};
    margin: 0 0 30px 0;
  }
`
import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print';

// components internos
import List from './List'
import Recibo from './Recibo'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section } from './style'

// rest
import { useGet } from '~/services/rest'
import { useParams } from 'react-router-dom'
import BackButton from '~/components/BackButton'
import Button from '~/components/form/Button'
import { Link } from 'react-router-dom'

const PurchasesHistoric = () => {

  // parametros de rota
  const { idHistorico } = useParams()
  const refPrint = useRef()

  const historic = useGet(idHistorico ? `relatorios/historicoCompras/${idHistorico}` : `relatorios/historicoCompras/`);

  return (
    <TemplateHome>
      <Section>

        {
          !historic.loading && historic.data.length > 0 &&
            idHistorico ?
            <>
              <BackButton />
              <h1>Recibo</h1>
              <div className='text-right mb30'>
                <Recibo historic={historic} ref={refPrint} />

                <ReactToPrint
                  trigger={() => <Button className='smaller'>Imprimir</Button>}
                  content={() => refPrint.current}
                />
              </div>

              <p>Caso você tenha dúvidas sobre este recibo, entre em contato com nossa <Link to='/suporte'>equipe de suporte</Link>.</p>
            </>
            :
            <>
              <h1>Histórico de compras</h1>
              <List historic={historic} />
            </>
        }

      </Section>
    </TemplateHome>
  )
}

export default PurchasesHistoric
import styled from 'styled-components'

export const TextareaStyled = styled.textarea`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 127px;
  font-size: 15px;
  padding: 10px 15px;
  border: 1px solid #707070;
  border-radius: 8px;
  outline: none;
`
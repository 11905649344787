import styled from 'styled-components'
import { color, media } from '~/styles/variables'

// grid
import { gridRightSideBar } from '~/styles/gridSystem'

export const Container = styled.div`
  ${gridRightSideBar}

  ${media.sm}{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
  }
`
export const Logo = styled.img`
  display: block;
  margin: 0 auto;
  margin-top: 35px;
  width: 145px;
  max-width: 100%;

  ${media.xl}{
    width: 177px;
  }
`
export const Box = styled.div`
  width: 100%;
  background: #f5f5f5;
  padding: 15px 10px;
  margin-top: 60px;

  ${media.lg}{
    padding: 20px 15px;
  }
  ${media.xl}{
    padding: 35px;
  }

  h3{
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    color: ${color.second};

    strong{
      display: inline-block;
    }
  }

  .desc-content-course, .component-unfinished-class{
    display: none;
  }
`

import React, { useEffect, useState } from 'react'

// style
import { Container } from './style'

// components
import { LoadingMainTitle } from '~/components/Loading'

// rest
import { useGet } from '~/services/rest'

// functions
import { stripSlashes } from '~/services/functions'

const HeaderCourse = ({ idHistoricCourse, errorCourse }) => {

  const [loading, setLoading] = useState(true)

  const userCourse = useGet(idHistoricCourse ? `historicocursos/${idHistoricCourse}` : false)

  useEffect(() => {
    if (errorCourse) {
      setLoading(false)
    }
    if (userCourse.data.length > 0) {
      setLoading(false)
    }
  }, [userCourse, setLoading, errorCourse])

  if (loading) {
    return (
      <Container>
        <LoadingMainTitle />
      </Container>
    )
  }
  if (errorCourse) {
    return (
      <Container>
        <h1>Erro!</h1>
        <p>Atualmente este curso não esta disponível no seu perfil ou não existe.</p>
      </Container>
    )
  }
  return (
    <Container>
      <h1>{stripSlashes(userCourse.data[0].cursos.titulo)}</h1>
    </Container>
  )
}
export default HeaderCourse
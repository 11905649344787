import styled from 'styled-components'
import { color } from '~/styles/variables'

export const Container = styled.div`
  // display: inline-block;
  text-align: center;
  margin-bottom: 15px;

  span {
    font-size: 14px;
    color: ${color.third};

    span.link {
      cursor: pointer;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

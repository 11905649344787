import React, { useState, useEffect, useContext } from 'react'

// Context
import { AuthContext } from '~/hooks/auth'

// templates
import TemplateHome from '~/templates/Home'

// styles
import { Section, WrapFormChangePassword } from './style'

// components
import Label from '~/components/form/Label'
import Input from '~/components/form/Input'
import Button from '~/components/form/Button'
import MsgResponse from '~/components/MsgResponse'

// rest
import { usePut } from '~/services/rest'

const ChangePassword = () => {

  const auth = useContext(AuthContext)

  const [email, setEmail] = useState()
  const [passwordData, setPasswordData] = useState({
    senha: '',
    nova_senha: '',
  })

  useEffect(() => {
    if (auth.user) {
      setEmail({ email: auth.user.email })
    }

  }, [auth])

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const handleFormFill = field => evt => {
    setPasswordData({
      ...passwordData,
      [field]: evt.target.value
    })
  }

  const [resChangePassword, changePassword] = usePut(`usuarios/senha`)

  useEffect(() => {
    if (resChangePassword.error) {
      setSuccess(null)
      setError(resChangePassword.error)
    } else if (resChangePassword.data.length > 0 || resChangePassword.data.sucesso) {
      setError(null)
      setSuccess("Sua senha foi atualizada!")
    }
  }, [resChangePassword, setError, setSuccess])

  const handleUpdatePassword = async () => {

    setError(null)
    setSuccess(null)

    if (
      !email ||
      !passwordData.senha ||
      !passwordData.nova_senha
    ) {
      setError('Por favor, preencha todos os campos!')
      return false
    }

    const data = { ...passwordData, ...email }

    changePassword(data)
  }

  return (
    <TemplateHome>
      <Section>
        <h1>Meu Perfil</h1>

        <WrapFormChangePassword>

          <h3>Trocar senha</h3>

          <Label htmlFor='fm_senha'>Sua senha:</Label>
          <Input type="password" value={passwordData.senha} onChange={handleFormFill('senha')} id='fm_senha' />
          <Label htmlFor='fm_nova_senha'>Nova senha:</Label>
          <Input type="password" value={passwordData.nova_senha} onChange={handleFormFill('nova_senha')} id='fm_nova_senha' />

          {error && <MsgResponse msg={error} theme="error" />}
          {success && <MsgResponse msg={success} theme="success" />}

          <br />

          <Button onClick={() => handleUpdatePassword()}>Atualizar</Button>
        </WrapFormChangePassword>

      </Section>
    </TemplateHome>
  )
}

export default ChangePassword
import styled from 'styled-components'
import { media } from '~/styles/variables'

export const Section = styled.section`
  max-width: 100%;
  width: 960px;
`
export const WrapListTable = styled.div`
  max-width: 100%;
  border-radius: 8px;
  background: #f5f5f5;
  margin-bottom: 30px;
  padding: 20px 30px;

  ${media.lg}{
    padding: 30px 57px;
    margin-bottom: 30px;
  }

  @media print {
    padding: 0;
  }

  table.table{
    > thead{
      >tr{
        >th{
          text-align: left;
          vertical-align: middle;
          white-space: nowrap;
          padding: 10px 20px;
        }
      }
    }
    > tbody{
      >tr{
        >td{
          text-align: left;
          vertical-align: middle;
          padding: 10px 20px;
          vertical-align: middle;

          a{
            width: 100px;
            height: 35px;
          }
        }
      }
    }

    &.table-list{
      > thead{
        >tr{
          >th{
            &:nth-child(1){
              padding: 10px;
            }
            &:nth-child(2){
              width: 100%;
            }
          }
        }
      }
      > tbody{
        >tr{
          >td{
            &:nth-child(1){
              padding: 10px;
            }
            &:nth-child(3){
              white-space: nowrap;
            }
          }
        }
      }
    }
    &.table-recibo{
      > thead{
        >tr{
          >th{
            &:nth-child(1){
              width: 100%;
            }
            &:nth-child(2){
              width: 100%;
            }
          }
        }
      }
      > tbody{
        >tr{
          >td{
            &:nth-child(1){
              width: 100%;
            }
            &:nth-child(2){
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
`

export const WrapRecibo = styled.div`
  max-width: 100%;

  @media print {
    padding: 30px;

    h3,
    h2,
    h4,
    p{
      text-align: left;
    }
  }

`
export const WrapHead = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${media.sm}{
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
  }

  @media print {
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
  }
`
export const WrapMarca = styled.div`
  width: 350px;
  max-width: 100%;
  margin-right: 15px;
`
export const WrapInfo = styled.div`
  max-width: 100%;

  ${media.sm}{
    margin-right: 15px;
  }
`
import styled from 'styled-components'
import { media } from '~/styles/variables'

export const Section = styled.section`
  width: 100%;
  margin: 60px 0;
  text-align: center;

  ${media.sm}{
    text-align: left;
  }

  p{
    padding: 0 15px;
  }

  
  a.see-more{
    vertical-align: middle;
    color: #FFFFFF;
    margin-left: 15px;
    height: 227px;
    width: 270px;
    max-width: 100%;
    font-size: 20px;
  }
`
import styled from 'styled-components'
import { color, font, media } from '~/styles/variables'
import * as mixin from '~/styles/mixins'

// grid
import { gridLeftSidebar } from '~/styles/gridSystem'

// imagem
import defaultAvatar from '~/assets/default-avatar.jpg'

export const Container = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${color.primary};
  display: flex;
  flex-direction: column;
  ${gridLeftSidebar}
`
export const Header = styled.header`
  display: flex;
  flex-direction: column;
  padding: 15px 2px;

  ${media.sm}{
    padding: 30px 10px;
  }
  ${media.xl}{
    padding: 30px 25px;
  }

  h4{
    font-family: ${font.second};
    font-size: 13px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    font-weight: 400;

    ${media.max_xs}{
      display: none;
    }

    ${media.xl}{
      font-size: 14px;
    }
  }

  a{
    &:hover{
      >div{
        border-color: ${color.primary};
        .bg{
          ${mixin.transform('scale(1.1)')};
        }
      }
      h4{
        font-weight: 700;
      }
    }
  }
`
export const Content = styled.div`
  display: flex;
  flex: 1;
  padding: 30px 0;
  overflow: -moz-scrollbars-none;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  ${media.xl}{
    padding: 30px 15px;
  }

  &::-webkit-scrollbar {
    width: 0 !important
  }
`
export const Footer = styled.footer`
  display: flex;
  padding: 15px 2px;

  ${media.sm}{
    padding: 30px 10px;
  }
  ${media.xl}{
    padding: 30px 25px;
  }
`
export const Avatar = styled.div`
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
  position: relative;
  border: 1px solid transparent;
  ${mixin.transition('all .1s linear')};

  .bg{
    background-image: url(${props => props.avatar ? props.avatar_url : defaultAvatar});
    ${mixin.transition('all .2s ease-in-out')};
  }
`

export const Menu = styled.nav`
  width: 100%;

  ul{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
      display: block;

      &:not(:last-child){
        margin-bottom: 30px;
      }

      a, button{
        display: block;
        width: 100%;
        font-family: ${font.second};
        font-size: 12px;
        background: none;
        outline: none;
        border: none;
        color: #FFFFFF;
        text-align: center;
        padding: 5px;
        line-height: 1;

        ${media.xl}{
          font-size: 14px;
        }

        span.text{
          ${media.max_xs}{
            display: none;
          }
        }

        img{
          display: block;
          margin: 0 auto 15px auto;

          ${media.max_md}{
            width: 30px;
          }
        }

        &:hover{
          opacity: .8;
        }
      }
    }
  }
`